<!-- 班级登记管理-学员 -->
<template>
    <div class="Trainee">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">班级登记管理-学员</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 功能按钮 -->
        <div class="" style="margin-left:22px ;">
            <el-button size="small" icon="el-icon-refresh" 
                style="background-color: #2C3E50; border-radius: 3px;"
                @click="refresh">
                <!-- 刷新 -->
            </el-button>
            <el-button size="small" icon="el-icon-plus" style="background: #18BC9C;color: #FFFFFF;"
                @click="newlyAdded">
                新增
            </el-button>
            <!-- <button class="collection" @click="collection">收款码</button> -->
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border stripe style="width:97.9%;flex: 1;" height="650" >
            <el-table-column type="selection" align="center" width="50">
            </el-table-column>
            <el-table-column type="index" label="序号" align="center" width="80">
            </el-table-column>
            <el-table-column prop="student_name"
             :sortable="true" :sort-method="fn2" label="学生姓名" align="center" width="120">
            </el-table-column>
            <el-table-column prop="grade" sortable label="学年" align="center" width="120">
            </el-table-column>
            <el-table-column prop="train_status" sortable label="学籍类" align="center" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.train_status==1">开班入学</span>
                    <span v-else-if="scope.row.train_status==2">插班入学</span>
                    <span v-else-if="scope.row.train_status==3">转班入学</span>
                    <span v-else-if="scope.row.train_status==4">结业1</span>
                    <span v-else-if="scope.row.train_status==5">结业2</span>
                    <span v-else-if="scope.row.train_status==6">结业3</span>
                    <span v-else-if="scope.row.train_status==7">退学</span>
                </template>
            </el-table-column>
            <el-table-column prop="remark" sortable label="说明" align="center" width="300">
            </el-table-column>
            <el-table-column prop="registration" sortable label="报名日期" align="center" width="150">
            </el-table-column>
            <el-table-column prop="peyment_date" sortable label="交费日期" align="center" width="150">
            </el-table-column>
            <el-table-column prop="bm_lecture_number" sortable label="上课次数" align="center" width="120">
            </el-table-column>
            <el-table-column prop="discount_ratio" sortable label="优惠率(%)" align="center" width="120">
            </el-table-column>
            <el-table-column prop="discount_price" sortable label="让利" align="center" width="120">
            </el-table-column>
            <el-table-column prop="tuition_incidentals" sortable label="应收学杂费" align="center" width="130">
            </el-table-column>
            <el-table-column prop="tuition" sortable label="其中学费" align="center" width="130">
            </el-table-column>
            <el-table-column prop="incidentals" sortable label="杂费" align="center" width="130">
            </el-table-column>
            <el-table-column prop="actual_amount" sortable label="实收金额" align="center" width="130">
            </el-table-column>
            <el-table-column prop="deposit" sortable label="定金" align="center" width="130">
            </el-table-column>
            <el-table-column prop="drop_out" sortable label="退(转)学日期" align="center" width="150">
            </el-table-column>
            <el-table-column prop="refund_amount" sortable label="退(转)费" align="center" width="120">
            </el-table-column>
            <el-table-column prop="registration_type" sortable label="报班" align="center" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.registration_type==1">报本班</span>
                    <span v-else-if="scope.row.registration_type==2">报多班</span>
                </template>
            </el-table-column>
            <el-table-column prop="bz_tuition" sortable label="学费标准" align="center" width="150">
            </el-table-column>
            <el-table-column prop="bz_lecture_number" sortable label="授课次数" align="center" width="120">
            </el-table-column>
            <el-table-column prop="student_card" sortable label="学生卡号" align="center" width="150">
            </el-table-column>
            <el-table-column prop="" sortable label="学生描述" align="center" width="300">
            </el-table-column>
            <el-table-column  label="操作" align="center" fixed="right" width="200">
                <template slot-scope="scope">
                    <el-button class="turn" size="mini" @click="ShiftTransfer">
                        转班
                    </el-button>
                    <!-- <el-button icon="el-icon-edit" size="mini" style="background:#18BC9C;color: #fff;" 
                     @click="edit(scope.$index, scope.row)">
                        编辑
                    </el-button> -->
                    <el-button  icon="el-icon-delete" size="mini" style="background:#E74C3C;color: #fff;">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--分页 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[20,50,100,200,500,1000]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
</template>

<script> 
    export default{
        data() {
            return{
                tableData:[],
                currentPage: 1,
                currentLimit: 20, //条数
                total: 0,
            }
        },
        created() {
            this.getList()
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            },
            getList(){
               this.$request({
                   url:'/api/studenttrain/list',
                   method:'POST',
                   data:{
                       class_id:this.$route.query.id,
                       limit:this.currentLimit,
                       page:this.currentPage
                   }
               }).then(res=>{
                   console.log(res)
                   if(res.code==1){
                       this.tableData=res.data.list
                       this.total=res.data.count
                   }
               }) 
            },
            handleSizeChange(val) {
                this.currentLimit=val
                this.getList()
                // console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.currentPage=val
                this.getList()
                // console.log(`当前页: ${val}`);
            },
            // 刷新
            refresh() {
                this.$router.push({
                    path: "/home/newpage", //空页面路由
                    query: {
                        path: this.$route.path
                    }
                })
            },
            newlyAdded() { //新增
                this.$router.push({
                    path: "/student/addStudentData"
                })
            },
            edit(index,row) { //编辑
                this.$router.push({
                    path: "editTrainee"
                })
            },
            collection(){//收款码
                this.$router.push({
                    path: "collectionCode"
                })
            },
            ShiftTransfer(){//转班
                this.$router.push({
                    path: "shiftTransfer"
                })
            },
            // 姓名排序
            fn2(a, b){
                return a.student_name.localeCompare(b.student_name)
            }
        }
    }
</script>

<style scoped="scoped">
    .Trainee{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 20px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 按钮 */
    ::v-deep .el-icon-refresh {
        font-weight: bold !important;
        color: #FFFFFF;
    }
    
    ::v-deep .el-icon-plus {
        font-weight: bold !important;
    }
   
    /* 更多 */
    .collection{
        display: inline-block;
        width: 80px;
        height: 30px;
        vertical-align: middle;
        background: #29726C;
        color: #FFFFFF;
        border-radius: 3px;
        margin-left: 10px;
        border: none;
        cursor: pointer;
    }
    /* 表格 */
    .el-table {
        margin: 20px;
    }
    
    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 30px;
    }
    .turn{
        padding-left: 35px;
        background: url(../../assets/switch.png) 10px 5px no-repeat #F0AD4E;
        color: #fff;
    }
    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active{
        background-color: #2C3E50 !important;
    }
</style>
